.latex-content .katex {
    color: yellow !important;
  }
  
  .latex-content .katex .katex-html {
    color: yellow !important;
  }
  
  
  .latex-content .specific-katex-class {
    color: yellow !important;
  }
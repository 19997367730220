.container {
  display: flex;
  flex-direction: column;
}

/* For tablet and larger screens */
@media (min-width: 768px) {
  .container {
    flex-direction: row;
  }
}



.box {
  width: 100%;
  height: 100px;
  border: 1px solid black;
  margin: 10px;
  border-radius: 10px;
}


.latex-content .katex {
  color: yellow !important;
}

.latex-content .katex .katex-html {
  color: yellow !important;
}


.latex-content .specific-katex-class {
  color: yellow !important;
}




.markdown-s p {
  font-size: 15px !important;
  font-family: 'siyuan' !important;
}

.markdown-r code {
  font-size: 15px !important;
  font-family: 'siyuan' !important;
  color: white;
}

.markdown-r p {
  font-size: 15px !important;
  font-family: 'siyuan' !important;
}
.markdown-r h1 {
  font-size: 22px !important;
  font-family: 'siyuan' !important;
  font-weight: bold !important;
}

.markdown-r h2 {
  font-size: 21px !important;
  font-family: 'siyuan' !important;
  font-weight: bold !important;
}

.markdown-r h3 {
  font-size: 19px !important;
  font-family: 'siyuan' !important;
  font-weight: bold !important;
  font-weight: bold !important;
}

.markdown-r h4 {
  font-size: 17px !important;
  font-family: 'siyuan' !important;
  font-weight: bold !important;
}

.markdown-r h5 {
  font-size: 17px !important;
  font-family: 'siyuan' !important;
  font-family: 'siyuan' !important;
  font-weight: bold !important;
}

.markdown-r strong {
  font-size: 16px !important;
  font-family: 'siyuan' !important;
}

.markdown-r li {
  font-size: 16px !important;
  font-family: 'siyuan' !important;
}




/* 隐藏 ol 和 ul 列表的标记 */
.markdown-r ol, .markdown-r ul {
  list-style: none; /* 取消列表标记 */
  padding-left: 0; /* 去除默认的左边距 */
}

/* 针对所有列表项，隐藏 marker 伪元素 */
.markdown-r ol li::marker, .markdown-r ul li::marker {
  content: none; /* 移除 marker 内容 */
}




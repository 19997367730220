.languages-label {
  margin-top: 40px;
  margin-bottom: 20px;
  color: #e0e0e0;
}


.custom-slider {
  -webkit-appearance: none; /* Override default appearance */
  width: 100%; /* Full-width */
  height: 15px; /* Specified height to make it larger */
  background: #ccc; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  transition: opacity 0.2s; /* 0.2 seconds transition on hover */

  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 30px;
  
}

.custom-slider:hover {
  opacity: 1; /* Fully visible on hover */
}

.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default appearance */
  width: 25px; /* Set a specific width */
  height: 25px; /* Height */
  background: #4caf50; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%;
}

.custom-slider::-moz-range-thumb {
  width: 25px; /* Set a specific width */
  height: 25px; /* Height */
  background: #4caf50; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%;
}

/* WangEditor 明亮舒适主题 */
.w-e-toolbar, 
.w-e-text-container {
    background-color: #f4f7fa !important; /* 柔和的淡灰背景 */
    color: #333 !important; /* 黑色文字 */
}

/* 菜单栏按钮 */
.w-e-toolbar .w-e-menu {
    color: #333 !important; /* 按钮文字颜色 */
    background: #e2e9f0 !important; /* 浅灰背景 */
    border: 1px solid #ccc !important;
}

/* 菜单按钮悬停效果 */
.w-e-toolbar .w-e-menu:hover {
    background-color: #d1d9e6 !important; /* 鼠标悬停时的柔和蓝色 */
}

/* 菜单激活状态 */
.w-e-toolbar .w-e-menu.w-e-active {
    color: #0078d4 !important; /* 激活项的蓝色 */
}

/* 编辑区域 */
.w-e-text-container {
    border: 1px solid #d1d9e6 !important;
}

/* 编辑区域文字 */
.w-e-text-container p, 
.w-e-text-container span {
    color: #333 !important;
}

/* 占位符文本 */
.w-e-text-placeholder {
    color: #aaa !important; /* 浅灰色占位符 */
}

/* 超链接样式 */
.w-e-text-container a {
    color: #0078d4 !important; /* 蓝色链接 */
}

/* 代码块 */
.w-e-text-container pre {
    background-color: #f5f5f5 !important; /* 代码块背景色 */
    color: #333 !important; /* 代码块文字颜色 */
    border-radius: 5px;
    padding: 8px;
}

/* 引用块 */
.w-e-text-container blockquote {
    border-left: 4px solid #0078d4 !important; /* 引用块的蓝色左边框 */
    color: #555 !important; /* 引用块文字 */
    background: #f0f4f8 !important; /* 引用块柔和的背景色 */
    padding: 8px;
}

/* 表格 */
.w-e-text-container table {
    border: 1px solid #ccc !important;
    background: #ffffff !important;
}

.w-e-text-container td, 
.w-e-text-container th {
    border: 1px solid #ddd !important;
    color: #333 !important;
}

/* 分割线 */
.w-e-text-container hr {
    border: 1px solid #e0e0e0 !important; /* 浅灰色分割线 */
}









/* 修改下拉菜单整体背景色 */
.w-e-select-list {
  background-color: #f4f7fa !important; /* 设置背景色 */
  border: 1px solid #ccc !important; /* 设置边框 */
}

/* 修改下拉菜单项的默认文字颜色 */
.w-e-select-list li {
  color: #333 !important; /* 设置默认文字颜色 */
  background-color: #ffffff !important; /* 设置默认背景色 */
  padding: 8px 12px;
  cursor: pointer;
  transition: background 0.3s ease;
}

/* 修改鼠标悬停时的样式 */
.w-e-select-list li:hover {
  background-color: #e6eff5 !important; /* 设置鼠标悬停时的背景色 */
  color: #0078d4 !important; /* 设置鼠标悬停时的文字颜色 */
}

/* 修改选中状态的菜单项 */
.w-e-select-list li.selected {
  background-color: #0078d4 !important; /* 选中状态的背景色 */
  color: #ffffff !important; /* 选中状态的文字颜色 */
  font-weight: bold;
}

  



.w-e-text-pre code {
    background: #282c34 !important; /* 代码背景 */
    color: #abb2bf !important; /* 默认字体颜色 */
    font-family: 'Courier New', monospace;
  }
.ql-editor {
    color: black; /* 设置文字颜色为黑色 */
}

.ql-editor img {
    width: auto;  /* 使用图片的原始宽度 */
    max-width: 100%; /* 确保图片不会超出编辑器的宽度 */
    height: auto; /* 使用图片的原始高度 */
}


.CodeMirror {
    border: 1px solid #eee;
    height: 100%;
   }
   
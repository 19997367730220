.wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.sliders {
    display: grid;
    grid: auto-flow auto / auto 1fr minmax(60px, max-content);
    row-gap: 6px;
    column-gap: 12px;
}

.sliders label {
    text-align: right;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

.slider-value {
    opacity: 0.4;
}

#myChart {
    flex: 1;
}

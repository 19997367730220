:root {
  --color: #fff;
  --colorBlack: #333;
  --padding-10: 10px;
  --padding-20: 20px;
  --plane-radius: 10px;
  --plane-bg: transparent;
  --fontSize: 14px;
  --headerFont: 24px;
  --border-color: #ccc;
}
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-size: var(--fontSize);
}
html,
body {
  width: 100%;
  height: 100%;
  min-width: 1000px;
  min-height: 600px;
  margin: 0px;
  padding: 0px;
  color: var(--color);
  font-family: 'siyuan' !important;
}
#root,
.page-content {
  width: 100%;
  height: 100%;
  font-family: 'siyuan' !important;
}
.page-content {
  padding: var(--padding-10);
  display: flex;
}
.page-content .login .btn {
  text-align: center;
}
.page-content .login .btn a + a {
  margin-left: 20px;
}
.page-content .main {
  padding: 0px var(--padding-10) var(--padding-10) var(--padding-10);
  width: 100%;
  overflow: auto;
}
.page-content .sidebar {
  display: flex;
  flex-direction: column;
  width: 210px;
  height: 100%;
  min-width: 210px;
  border-radius: var(--plane-radius);
  background-color: #fff;
  transition: all 0.3s ease-in;
}
.page-content .sidebar.is-collapse {
  width: auto;
  min-width: auto;
}
.page-content .sidebar + .mask {
  display: none;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}
.page-content .sidebar .menu {
  height: calc(100% - 100px);
  color: #9a9a9a;
  overflow: auto;
  font-family: 'siyuan' !important;
}
.page-content .sidebar .menu .icon {
  min-width: unset;
}
.page-content .sidebar .menu .text {
  margin-left: 6px;
  font-family: 'siyuan' !important;
}
.page-content .sidebar .tools {
  justify-content: start;
  height: 50px;
}
.page-content .sidebar .tools .is-collapse {
  display: block;
}
.page-content .sidebar .tools .is-close {
  display: none;
}
.page-content .header {
  padding: 4px var(--padding-10);
  margin-bottom: var(--padding-10);
  position: sticky;
  top: 0px;
  background-color: #141e30;
  z-index: 1000;
  font-family: 'siyuan','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.page-content .header .header-left {
  display: flex;
  align-items: center;
}
.page-content .header .header-left .tips {
  display: inline-block;
}
.page-content .header .header-left .icon {
  width: 20px;
  height: 20px;
  fill: #fff;
  display: none;
}
.page-content .header .header-left * {
  color: #fff;
}
.page-content .header .header-right {
  text-align: right;
}
.page-content .tools {
  padding: 0px 10px;
  color: #9a9a9a;
  display: flex;
  justify-content: end;
}
.page-content .tools .tool-item {
  cursor: pointer;
}
.page-content .tools .tool-item + .tool-item {
  margin-left: var(--padding-10);
}
.page-content .select {
  padding-top: 6px;
  padding-bottom: 6px;
  margin: 0px;
  color: var(--color);
}
.page-content .select .MuiSelect-select {
  padding: 0px;
  font-size: var(--fontSize);
}
.page-content .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
.page-content .css-bhp9pd-MuiPaper-root-MuiCard-root {
  border-radius: var(--plane-radius);
}
.page-content .css-79elbk {
  border-radius: var(--plane-radius);
}
.page-content .home .header {
  background-color: transparent;
}
.page-content .popover {
  padding: var(--padding-10);
  background-color: var(--plane-bg);
  border-radius: var(--plane-radius);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.5);
  color: var(--colorBlack);
}
.page-content .popover button {
  font-size: var(--fontSize);
  margin-top: 0px;
  color: var(--colorBlack);
}
.page-content .popover .css-3odgu4-MuiSvgIcon-root,
.page-content .popover span {
  color: var(--colorBlack);
}
.page-content .popover li {
  display: flex;
}
.page-content .popover .sc-ejCKsa {
  display: flex;
}
.page-content .popover .label,
.page-content .popover label {
  display: block;
  width: 80px;
}
.page-content .popover .label::after,
.page-content .popover label::after {
  content: "：";
}
.page-content .popover select {
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.page-content .popover .bIDKRA {
  height: 40px;
}
.page-content .popover .tool {
  justify-content: end;
}
.page-content .card {
  padding: var(--padding-20);
  border-radius: var(--plane-radius);
  border: var(--border-color) 1px solid;
}
.page-content .home-box {
  padding: var(--padding-20);
  display: flex;
  justify-content: center;
}
.page-content .home-box .hoome {
  flex-grow: unset;
}
.form-item {
  display: flex;
  align-items: center;
}
.form-item .label {
  width: 80px;
  min-width: 80px;
}
.form-item .label::after {
  content: "：";
}
.form-item .input {
  width: 100%;
}
.form-item.white-text label,
.form-item.white-text .label {
  color: #fff;
}
.form-item.white-bg select {
  background-color: transparent;
  color: var(--colorBlack);
  border-color: var(--border-color);
}
[role="dialog"] {
  border-radius: var(--plane-radius) !important;
}
[role="dialog"] h2 {
  color: var(--colorBlack);
}
.carousel.banner {
  height: 480px;
}
.carousel.banner img {
  width: 100%;
  height: 100%;
}
.page .row {
  padding: 30px var(--padding-10);
}
.page .row-header {
  text-align: center;
  padding-bottom: var(--padding-10);
  margin-bottom: var(--padding-10);
}
.page .row-header h1 {
  font-size: var(--headerFont);
}
.page .row-header .small {
  color: #9a9a9a;
}
.page .has-img {
  padding: var(--padding-20);
  position: relative;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page .has-img::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.7);
}
.page .has-img .btn {
  opacity: 0;
  width: 100px;
  position: relative;
  z-index: 2;
  background-image: linear-gradient(to left, #7a40b7 0%, #1d106c 100%);
  transition: all 0.3s ease-in;
}
.page .has-img img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
}
.page .has-img:hover .btn {
  opacity: 1;
}
.page .has-img .content {
  width: 50%;
  font-size: 18px;
}
.page .has-img h2,
.page .has-img p {
  line-height: 30px;
  color: #fff;
  position: relative;
  z-index: 1;
}
.page .has-img h2 {
  color: #fff;
  font-size: 20px;
  text-align: center;
}
.page .has-img p {
  line-height: 26px;
}
.page .card {
  border-radius: 10px;
  background-color: #fff;
  height: 100%;
  padding: var(--padding-20);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}
.page .card .img {
  display: flex;
  justify-content: center;
  margin-bottom: var(--padding-20);
}
.page .card .img img {
  width: 150px;
  height: 150px;
}
.page .card h2,
.page .card p {
  line-height: 30px;
  color: #9a9a9a;
}
.page .card h2 {
  color: #333;
  font-size: 18px;
}
.page .card p {
  line-height: 26px;
}
.katex-html {
  color: yellow;
}

/* 设定滚动条宽度 */
::-webkit-scrollbar {
  width: 10px;
}
/* 滚动条的滑块 */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
/* 滑块在鼠标悬停时的样式 */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* 滚动条的轨道 */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
/* 轨道在鼠标悬停时的样式 */
::-webkit-scrollbar-track:hover {
  background: #ddd;
}
/* 滚动条的拐角 */
::-webkit-scrollbar-corner {
  background: #555;
}
@media (max-width: 750px) {
  html,
  body {
    min-width: unset;
    min-height: unset;
  }
  .page .has-img .btn {
    opacity: 1;
  }
  .page-content .sidebar {
    position: absolute;
    z-index: 2000;
    display: none;
  }
  .page-content .sidebar + .mask {
    display: block;
  }
  .page-content .sidebar .tools .is-collapse {
    display: none;
  }
  .page-content .sidebar .tools .is-close {
    display: block;
  }
  .page-content .header .header-left .icon {
    display: inline-block;
  }
  .page-content .header .header-left .tips {
    display: none;
  }
}

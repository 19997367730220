.chat-window {
  background-color: #02081d;
  color: white;
  font-size: 17px;
  width: 100%;
  height: 25vh;
  border: none;
  resize: none;
}

.input-container {
  display: flex;
  align-items: center;
}

.text-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  padding: 20px;
}

/* text input */
input[type='text'] {
  font:
    15px/24px 'Muli',
    sans-serif;
  color: white;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}
:focus {
  outline: none;
}
.message-input-container {
  float: left;
  width: 50vw;
  margin: 15px 3%;
  position: relative;
}
input[type='text'] {
  font:
    15px/24px 'Lato',
    Arial,
    sans-serif;
  color: white;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}
.message-input {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 7px 14px 9px;
  transition: 0.4s;
  font-size: 20px;
  display: flex;
  color: white;
  background-color: transparent;
}

.message-input ~ .focus-border:before,
.message-input ~ .focus-border:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #85a7ff;
  transition: 0.3s;
}
.message-input ~ .focus-border:after {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}
.message-input ~ .focus-border i:before,
.message-input ~ .focus-border i:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #85a7ff;
  transition: 0.4s;
}
.message-input ~ .focus-border i:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}
.message-input:focus ~ .focus-border:before,
.message-input:focus ~ .focus-border:after {
  width: 100%;
  transition: 0.3s;
}
.message-input:focus ~ .focus-border i:before,
.message-input:focus ~ .focus-border i:after {
  height: 100%;
  transition: 0.4s;
}

.options-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 40px;
  bottom: 0;
  width: 100%;
}

.search-checkbox {
  margin-top: 15px;
  color: white;
}

.white {
  color: white;
}

.talk-on {
  color: red;
}

.talk-off {
  color: white;
}

.recording-animation {
  animation: pulsate 1s infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.ant-menu,.ant-menu-sub,.ant-menu-inline{
  color: white;
  /* background-color: #044d50 !important; */
  align-items: center;
  text-align: center;

}

.custom-select .ant-select-selector {
  color: red;
}
.ant-message-notice-content {
  color: red !important;
}

/* 在您的 CSS 文件中或者 <style> 标签中添加 */
.blueSummary {
  color: blue !important; /* 使用 !important 确保样式应用 */
}


.MuiPaginationItem-page.Mui-selected {
    background-color: #3b99ff !important;
    color: #fff !important;
  }
  
  .MuiPaginationItem-root {
    color: #fff !important;
  }




  .centered-markdown {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    line-height: 1.6;
    margin: 0 auto;
    max-width: 800px;
    padding: 20px;
  }
  
  .centered-markdown h1,
  .centered-markdown h2,
  .centered-markdown h3,
  .centered-markdown h4,
  .centered-markdown h5,
  .centered-markdown h6 {
    color: white !important; /* 使用 !important 来强制应用颜色 */
  }
  
  .centered-markdown p {
    color: white !important; /* 使用 !important 来强制应用颜色 */
  }
  
  /* 使用更具体的选择器来针对特定文本段落 */
  .centered-markdown ul li {
    color: white !important; /* 为无序列表项设置颜色 */
    font-size: 1rem;
  }
  
  .centered-markdown ol li {
    color: white !important; /* 为有序列表项设置颜色 */
    font-size: 0.9rem;
  }
  
  /* 添加更多样式属性，根据需要定义其他元素的样式 */
  
/* 默认样式（可能适用于较大的桌面屏幕） */
.centered-markdown h1 {
  font-size: 2rem;
}

.centered-markdown h2 {
  font-size: 1.5rem;
}

.centered-markdown p {
  font-size: 1rem;
}

/* 平板电脑 */
@media (max-width: 1024px) {
  .centered-markdown h1 {
    font-size: 2rem;
  }

  .centered-markdown h2 {
    font-size: 1.7rem;
  }

  .centered-markdown p {
    font-size: 1.1rem;
  }
}

/* 手机 */
@media (max-width: 768px) {
  .centered-markdown h1 {
    font-size: 1.5rem;
  }

  .centered-markdown h2 {
    font-size: 1.3rem;
  }

  .centered-markdown p {
    font-size: 1rem;
  }
}



.markdown-doc p {
    font-size: 15px !important;
    font-family: 'siyuan' !important;
  }
  
.markdown-doc code {
    font-size: 15px !important;
    font-family: 'siyuan' !important;
    color: white;
  }
  
.markdown-doc p {
    font-size: 15px !important;
    font-family: 'siyuan' !important;
  }
.markdown-doc h1 {
    font-size: 22px !important;
    font-family: 'siyuan' !important;
    font-weight: bold !important;
  }
  
.markdown-doc h2 {
    font-size: 21px !important;
    font-family: 'siyuan' !important;
    font-weight: bold !important;
  }
  
.markdown-doc h3 {
    font-size: 19px !important;
    font-family: 'siyuan' !important;
    font-weight: bold !important;
    font-weight: bold !important;
  }
  
.markdown-doc h4 {
    font-size: 17px !important;
    font-family: 'siyuan' !important;
    font-weight: bold !important;
  }
  
.markdown-doc h5 {
    font-size: 17px !important;
    font-family: 'siyuan' !important;
    font-family: 'siyuan' !important;
    font-weight: bold !important;
  }
  
.markdown-doc strong {
    font-size: 16px !important;
    font-family: 'siyuan' !important;
  }
  
  .markdown-doc li {
    font-size: 16px !important;
    font-family: 'siyuan' !important;
}

/* 为无序列表设置实心圆点样式 */
.markdown-doc ul {
    list-style-type: disc;
    margin-left: 20px;
}

/* 为有序列表设置数字样式 */
.markdown-doc ol {
    list-style-type: decimal;
    margin-left: 20px;
}
  
  /* 针对所有列表项，隐藏 marker 伪元素 */
.markdown-doc ol li::marker, .markdown-r ul li::marker {
    content: none; /* 移除 marker 内容 */
  }
  
  
  
  
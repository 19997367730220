/* src/fonts.css */

@font-face {
    font-family: 'Noto Sans SC';
    src: url('./fonts/Noto_Sans_SC/NotoSansSC-VariableFont_wght.ttf') format('truetype');
    font-weight: 100 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Noto Sans SC Black';
    src: url('./fonts/Noto_Sans_SC/static/NotoSansSC-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Noto Sans SC Bold';
    src: url('./fonts/Noto_Sans_SC/static/NotoSansSC-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Noto Sans SC Light';
    src: url('./fonts/Noto_Sans_SC/static/NotoSansSC-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'siyuan';
    src: url('./fonts/siyuan/23606.otf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  /* 你可以根据需要继续添加其他字体 */
  
  .markdown {
    font-family: 'siyuan','Noto Sans SC', 'Noto Sans SC Black', 'Noto Sans SC Bold', 'Noto Sans SC Light', sans-serif;
    font-size: 16px;
  }

  .siyuanFont {
    font-family: 'siyuan','Noto Sans SC', 'Noto Sans SC Black', 'Noto Sans SC Bold', 'Noto Sans SC Light', sans-serif;
    font-size: 15px;
  }

 /* 浮动按钮点击弹窗的容器背景色设置 */
  .smallWindowBackgroundColor{
    padding: '20px';
    margin: '10px';
    background-color: '#003f51';
  }
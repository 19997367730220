.custom-editor .ql-container {
    background-color: #3b99ff !important;
    color: rgb(0, 0, 0) !important;
  }

  .ql-toolbar.ql-snow {
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    padding: 8px;
    background-color: #fff;
  }
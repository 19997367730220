


.centered-markdown p {
  font-size: 15px !important;
  font-family: 'siyuan' !important;

}

.centered-markdown code {
  font-size: 15px !important;
  font-family: 'siyuan' !important;
  color: white;
}

.centered-markdown h1 {
  font-size: 22px !important;
  font-family: 'siyuan' !important;
  font-weight: bold !important;
}

.centered-markdown h2 {
  font-size: 21px !important;
  font-family: 'siyuan' !important;
  font-weight: bold !important;
}

.centered-markdown h3 {
  font-size: 19px !important;
  font-family: 'siyuan' !important;
  font-weight: bold !important;
  font-weight: bold !important;
}

.centered-markdown h4 {
  font-size: 17px !important;
  font-family: 'siyuan' !important;
  font-weight: bold !important;
}

.centered-markdown h5 {
  font-size: 17px !important;
  font-family: 'siyuan' !important;
  font-family: 'siyuan' !important;
  font-weight: bold !important;
}

.centered-markdown strong {
  font-size: 16px !important;
  font-family: 'siyuan' !important;
}

.centered-markdown li {
  font-size: 16px !important;
  font-family: 'siyuan' !important;
}
